import React, { Component } from "react";
import i18next from "i18next";
//CSS
import './Clock.scss';

class Clock extends Component {
  constructor({position, visible}) {
    super();
    this.state = {
      time: new Date(),
    };
  }
  
  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  tick() {
    this.setState({
      ...this.state,
      time: new Date(),
    });
  }

  getDateString() {
    let dateString = ""
    switch (i18next.language) {
      case 'en':
        const weekday = this.state.time.toLocaleDateString(i18next.language, {weekday:'long'});
        dateString += weekday.charAt(0).toUpperCase() + weekday.slice(1);
        const month = this.state.time.toLocaleDateString(i18next.language, {month:'long'});
        dateString += ", " + month.charAt(0).toUpperCase() + month.slice(1);
        dateString += " " + this.state.time.toLocaleDateString(i18next.language, {day:'numeric'});
        break;
      case 'es':
      case 'pt':
        const weekday_es = this.state.time.toLocaleDateString(i18next.language, {weekday:'long'});
        dateString += weekday_es.charAt(0).toUpperCase() + weekday_es.slice(1);
        dateString += " " + this.state.time.toLocaleDateString(i18next.language, {day:'numeric'});
        const month_es = this.state.time.toLocaleDateString(i18next.language, {month:'long'});
        dateString += " de " + month_es.charAt(0).toUpperCase() + month_es.slice(1);
        break;
      default:
        break;
    }
    return dateString;
  }

  render() {
    return (
      <div className={'row ' + (this.props.visible ? 'visible' : 'invisible')}>
        {this.props.position === "right" ? <div className="col"></div> : null}
        <div className={'col clock mt-3 ' + (this.props.position === "right" ? "mr-3" : "ml-3")}>
          <h1 className={'clock-time text-'+this.props.position}>
            {this.state.time.toLocaleTimeString(i18next.language, {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </h1>
          <h2 className={'clock-date text-'+this.props.position}>{this.getDateString()}</h2>
        </div>
        {this.props.position === "left" ? <div className="col"></div> : null}
      </div>
    );
  }
}


export default Clock;
