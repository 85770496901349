import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";

import { Provider } from "react-redux";
import store from "./redux/store";

import * as serviceWorker from "./serviceWorker";
import "i18n"; //i18n Translation

import "theme/scss/main.scss";


ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    <BrowserRouter>
      <React.StrictMode>
        <Provider store={store}>
          <Routes />
        </Provider>
      </React.StrictMode>
    </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
