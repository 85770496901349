import { createStore, combineReducers } from "redux";
import reducers from "./reducers/_export";

const reducersCombined = combineReducers(reducers);

const store = createStore(
  reducersCombined,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
