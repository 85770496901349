import React from 'react';
import { useTranslation } from 'react-i18next';
import './LoginFooter.scss';

const LoginFooter = ({ footerText = null }) => {
  const { t } = useTranslation();

  const text =
    footerText && typeof footerText === 'string' && footerText !== ''
      ? footerText
      : t('footer.copyright', { this_year: new Date().getFullYear() });

  const lang = localStorage.getItem('i18nextLng') ?? 'es';

  return (
    <div className='footer bg-primary' id='login_footer'>
      <div className='row mt-1'>
        <div className='col-sm footer-text' id='footer_text'>
          {text} -{' '}
          <a
            target='_blank'
            href={window.location.origin + '/privacy_policy_' + lang + '.pdf'}
            style={{ color: '#ffffff' }}>
            {t('footer.privacy_policy')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginFooter;
