import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import './Loader.scss';

const Loader = ({ loading }) => {
  const [loaderState, setLoaderState] = React.useState(false);

  React.useEffect(() => {
    setLoaderState(loading);
  }, [loading]);

  return loaderState ? (
    <div id="loader">
      <CircularProgress size={40}/>
    </div>
  ) : null;
};

export default (Loader);
