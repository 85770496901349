import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL.replace(
    "[[platform]]",
    window.settings && window.settings.s1_platform ? window.settings.s1_platform : "qa" //TODO check this
  ),
});

export default api;
