// Clock actions
export const showClock = () => {
    return {
        type: 'SHOW_CLOCK',
    }
};
export const hideClock = () => {
    return {
        type: 'HIDE_CLOCK',
    }
};
export const changeClockPosition = (position) => {
    return {
        type: 'CHANGE_CLOCK_POSITION',
        position: position
    }
};