// Assets
import bkg1 from "assets/img/backgrounds/1.jpg";
// import bkg2 from "assets/img/backgrounds/2.jpg";
import bkg3 from "assets/img/backgrounds/3.jpg";
import bkg4 from "assets/img/backgrounds/4.jpg";
// import bkg5 from "assets/img/backgrounds/5.jpg";

const initialState =
  window.settings && window.settings.login_screen_style
    ? window.settings.login_screen_style
    : {
        white_label: {
          enabled: false,
        },
        login: {
          position: "center",
        },
        clock: {
          visible: true,
          position: "right",
        },
        background: {
          type: "random_image",
          images: [bkg1, bkg3, bkg4],
        },
      };

// Background images check to default images if null
if (
  initialState.background.type === "image" &&
  !initialState.background.image
) {
  initialState.background.image = bkg3;
}
if (initialState.background.type === "random_image") {
  if (!initialState.background.images) {
    initialState.background.images = [bkg1, bkg3, bkg4];
  } else {
    if (!initialState.background.images[0]) {
      initialState.background.images[0] = bkg1;
    }
    if (!initialState.background.images[1]) {
      initialState.background.images[1] = bkg3;
    }
    if (!initialState.background.images[2]) {
      initialState.background.images[2] = bkg4;
    }
  }
}

const reducerSettings = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_CLOCK": {
      return {
        ...state,
        clock: {
          ...state.clock,
          visible: true,
        },
      };
    }
    case "HIDE_CLOCK": {
      return {
        ...state,
        clock: {
          ...state.clock,
          visible: false,
        },
      };
    }
    case "CHANGE_CLOCK_POSITION": {
      return {
        ...state,
        clock: {
          ...state.clock,
          position: action.position,
        },
      };
    }
    default:
      return state;
  }
};

export default reducerSettings;
