import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
// CSS
import "./LoginForm.scss";
import eupaTranslationsOptions from "./eupaTranslations";
// Assets
import logoGreen from "assets/img/s1-logo-green.png";
// Components
import { S1Input } from "@slotone/react.common.s1-input";
import { S1Button } from "@slotone/react.common.s1-button";
import { S1Modal } from "@slotone/react.common.s1-modal";
import { S1InputDigit } from "@slotone/react.common.s1-input-digit";

/**
 * Login form
 * @param {string} position | "left", "center", "right"
 * @param {boolean} preview | flag to tell if it should behave as a preview
 * @param {string} logo | logo url, uses s1 by default
 * @param {string} errorMsg | error message to display
 * @param {boolean} s1Enabled | s1 auth flag to show username and password
 * @param {boolean} samlEnabled | saml flag to show saml button
 * @param {object} mfa | {enabled: true, engine: 'email'} object for displaying mfa inputs
 * @param {function} onSubmit | onSubmit login form
 */
const LoginForm = ({
	position = "center",
	preview = false,
	logo,
	errorMsg,
	s1Enabled,
	samlEnabled,
	mfa,
	onSubmit,
	eupa,
	reload,
	message
}) => {
	const { t } = useTranslation();
	let submitText = t("login_form.submit_button");
	const validationSchema = Yup.object().shape({
		username: !(mfa && mfa.enabled)
			? Yup.string().required(t("validation.user_pass"))
			: null,
		password: !(mfa && mfa.enabled)
			? Yup.string().required(t("validation.user_pass"))
			: null,
		validation_code:
			mfa && mfa.enabled
				? Yup.number()
					.required(t("validation.required"))
					.test(
						"len",
						t("validation.length", { length: 6 }),
						(val) => val && val.toString().length === 6
					)
				: null,
	});
	// MFA check
	if (mfa && mfa.enabled) {
		document.getElementById("login_inputs").style.display = "none";
		document.getElementById("mfa_input").style.display = "block";
		submitText = t("login_form.mfa_submit");
	}
	// EUPA check
	if (eupa && eupa.enabled) {
		//document.getElementById("login_inputs").style.display = "none";
		document.getElementById("eupa_input").style.display = "block";
		//submitText = t("login_form.eupa_submit");
	}
	let showmessage = t("validation." + message);
	if (showmessage === "validation." + message) {
		showmessage = message;
	}
	let retryButtonText;
	if (message.trim() === '') {
		retryButtonText = t("login_form.submit_button");
	} else {
		retryButtonText = t("validation.retry");
	}
	return (
		<div className={!samlEnabled ? "row mt-4" : "row"}>
			{position !== "left" ? (
				<div className={"col-" + getSideColumnSize()}></div>
			) : null}
			<div className={getPaddingForm() + " login-form col-8"} id="login_form">
				<div className="login-wrapper" id="login_wrapper">
					<div className="s1-logo">
						<img src={logo ? logo : logoGreen} alt="S1 Logo"></img>
					</div>
					{
						reload ? <div id="reload">
							<div className="text-center">
								<S1Button
									id={"btn_retry"}
									className={'w-100'}
									value={retryButtonText}
									onClick={() => { window.location.reload(false) }}
								/>
								<p
									className="login-error mt-2 mb-0"
								>
									{showmessage}
								</p>
							</div>
						</div>
							:
							<Formik
								initialValues={{
									username: window.credentials.username ? window.credentials.username : "",
									password: window.credentials.password ? window.credentials.password : "",
									mfa_token: mfa && mfa.token ? mfa.token : "",
									eupa_token: eupa && eupa.token ? eupa.token : "",
								}}
								onSubmit={(form, actions) => {
									// Handle submit
									if (onSubmit) {
										// MFA check
										if (mfa.enabled) {
											onSubmit(form, true);
										} else {
											// Call submit
											onSubmit(form);
										}
									}
									actions.setSubmitting(false);
								}}
								validateOnBlur={false}
								validateOnChange={true}
								validationSchema={validationSchema}
								enableReinitialize={true}
							>
								{({ isValid, dirty, values, setFieldValue, errors }) => {
									return (
										<Form>
											<div id="login_inputs">
												{/* SAML */}
												{samlEnabled ? (
													<React.Fragment>
														<div className="login-button">
															<S1Button
																id="button_saml"
																className="w-100"
																onClick={() => {
																	window.location.href =
																		`/login/api/public/saml2/sso/?lang=${localStorage.getItem("i18nextLng")}`;
																}}
																value={
																	samlEnabled && !s1Enabled
																		? submitText
																		: t("login_form.saml_text")
																}
																disabled={preview}
															/>
														</div>
														{/* S1 Login text */}
														{s1Enabled ? (
															<p className="s1-login-text">
																{t("login_form.s1_login_text")}
															</p>
														) : null}
													</React.Fragment>
												) : null}
												{/* S1 Auth (username and password) */}
												{s1Enabled ? (
													<React.Fragment>
														{/* Username */}
														<S1Input
															id="input_user"
															name="username"
															className="mb-2"
															placeholder={t("login_form.username")}
															disabled={preview}
															variant={"standard"}
															autoFocus
															required
														/>
														{/* Password */}
														<S1Input
															id="input_pwd"
															className="mb-2"
															name="password"
															placeholder={t("login_form.password")}
															type="password"
															disabled={preview}
															variant={"standard"}
															required
														/>
													</React.Fragment>
												) : null}
											</div>
											{/* MFA */}
											<div id="mfa_input" className="mb-2">
												<S1InputDigit
													id="input_mfa_code"
													className="mb-2"
													name="validation_code"
													label={
														mfa.engine === "totp_shared"
															? t("login_form.mfa_totp_code_label")
															: t("login_form.mfa_code_label", {
																engine: mfa.engine,
															})
													}
													setFieldValue={setFieldValue}
													disabled={false}
													required
												/>
											</div>
											{/* EUPA TODO CAMBIAR COMPONENTE */}
											<div id="eupa_input" className="mb-2">
												<S1Modal
													title={t("login_form.eupa_alert_title")}
													size="md"
													fullScreen={false}
													open={eupa.enabled && eupa.visible}
													onSubmit={() => {
														onSubmit(values, false, "", true, true);
													}}
													onClose={() => {
														onSubmit(values, false, "", true, false);
													}}
												>
													<div
														className="eupa_policy"
														dangerouslySetInnerHTML={{
															__html:
																eupaTranslationsOptions[
																t("login_form.eupa_policy")
																],
														}}
													/>
												</S1Modal>
												{/*}
										<S1Input
											id="input_mfa_code"
											className="mb-2"
											name="eupa_acceptance"
											type="checkbox"
											label={t("login_form.eupa_label")}
											setFieldValue={setFieldValue}
											disabled={false}
											required
										/>
                    {*/}
											</div>
											{/* Submit */}
											{s1Enabled || (mfa && mfa.enabled) ? (
												<div className="login-button">
													<S1Button
														id="submit_btn"
														value={submitText}
														type="submit"
														disabled={preview}
													/>
												</div>
											) : null}
											{/* Errors */}
											{errors &&
												(errors.username ||
													errors.password ||
													errors.validation_code) ? (
												<p
													data-testid="error_fields"
													className="login-error mt-2 mb-0"
												>
													{errors.username ||
														errors.password ||
														errors.validation_code}
												</p>
											) : null}
											{errorMsg ? (
												<p
													data-testid="error_submit"
													className="login-error mt-2 mb-0"
												>
													{errorMsg}
												</p>
											) : null}
										</Form>
									);
								}}
							</Formik>
					}
				</div>
			</div>
			{position !== "right" ? (
				<div className={"col-" + getSideColumnSize()}></div>
			) : null}
		</div>
	);

	function getPaddingForm() {
		switch (position) {
			case "right":
				return "login-padding-r";
			case "left":
				return "login-padding-l";
			default:
				return "login-padding";
		}
	}

	function getSideColumnSize() {
		if (position === "center") {
			return "2";
		} else {
			return "4";
		}
	}
};

export default LoginForm;
