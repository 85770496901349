import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translation_es from "translations/es/translation.json";
import translation_en from "translations/en/translation.json";
import translation_pt from "translations/pt/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";
const Languages = ["es", "en", "pt"];
i18n
	// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
	// learn more: https://github.com/i18next/i18next-http-backend
	//.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		lng: sessionStorage.getItem("s1_lang") ?? "es",
		fallbackLng: "es",
		debug: false,
		whitelist: Languages,
		resources: {
			es: {
				translation: translation_es,
			},
			en: {
				translation: translation_en,
			},
			pt: {
				translation: translation_pt,
			},
		},
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	});
document.documentElement.lang = i18n.language;
export default i18n;
