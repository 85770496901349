import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import api from "api";
// CSS
import "./BaseLogin.scss";
// Components
import LoginHeader from "components/LoginHeader/LoginHeader";
import LoginFooter from "components/LoginFooter/LoginFooter";
import Clock from "components/Clock/Clock";
import LoginForm from "components/LoginForm/LoginForm";
import Loader from "components/Loader/Loader";

class BaseLogin extends Component {
	constructor() {
		super();
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			loading: false,
			errorMsg: "",
			background_value: "",
			preview: false,
			clock: {},
			login: {},
			background: {},
			white_label: {},
			white_label_allowed:
				window.settings && window.settings.white_label_allowed,
			s1auth_enabled: window.settings && window.settings.s1auth_enabled,
			saml_enabled: window.settings && window.settings.saml_enabled,
			mfa: { enabled: false, engine: "", token: "" },
			eupa: {
				enabled: false,
				version: "",
				token: "",
				visible: false,
			},
			reload:
				window.settings &&
				window.settings.reload &&
				window.settings.reload === true,
			message:
				window.settings && window.settings.message
					? window.settings.message
					: "",
		};
	}

	/**
	 * When component is mounted set state to props and check if preview is enabled and set up background
	 */
	componentDidMount() {
		const { t, i18n } = this.props;
		// Session Timeout Error check
		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search);
		if (params.get("error") === "session_timeout") {
			this.setState({ errorMsg: t("session_timeout") });
		}

		const credentials = window.credentials ? window.credentials : null;

		if (credentials && credentials.username && credentials.password) {
			this.handleSubmit(credentials, false, "login/");
		}
		// SAML check
		const saml_response =
			window.settings && window.settings.saml_response
				? window.settings.saml_response
				: null;
		// Check if request comes from saml
		if (saml_response) {
			if (saml_response.success) {
				// MFA check
				if (saml_response.mfa_enabled) {
					this.setState({
						mfa: {
							enabled: saml_response.mfa_enabled,
							engine: saml_response.mfa_engine,
							token: saml_response.mfa_token,
						},
					});
				}
				if (saml_response.eupa_enabled) {
					this.setState({
						eupa: {
							enabled: saml_response.eupa_enabled,
							token: saml_response.eupa_token,
							version: saml_response.version,
							visible: saml_response.eupa_enabled,
						},
					});
				}
			} else {
				// Error
				let msg = saml_response.result_code
					? saml_response.result_code
					: t("error");
				this.setState({
					errorMsg: i18n.exists(msg) ? t(msg) : t("error"),
					loading: false,
				});
			}
		}
		// Check preview and set up background
		this.setState(this.props, () => {
			const isPreview = this.checkIfPreview();
			if (!isPreview) this.setUpBackground();
		});
	}

	/**
	 * Helper to check every null or empty background image and assign the default
	 */
	checkEmptyImages(settings) {
		let newSettings = settings;
		// Logo login
		const login = settings.login.image;
		if (
			!login ||
			(Object.keys(login).length === 0 && login.constructor === Object)
		) {
			newSettings.login.image = this.state.login.image;
		}
		// White Label
		const white_label_logo = settings.white_label.logo.image;
		if (
			!white_label_logo ||
			(Object.keys(white_label_logo).length === 0 &&
				white_label_logo.constructor === Object)
		) {
			newSettings.white_label.logo.image = this.state.white_label.logo.image;
		}
		// Image
		const background_image = settings.background.image;
		if (
			!background_image ||
			(Object.keys(background_image).length === 0 &&
				background_image.constructor === Object)
		) {
			newSettings.background.image = this.state.background.image;
		}
		// Random image
		for (let i = 0; i < settings.background.images.length; i++) {
			const random_image = settings.background.images[i];
			if (
				!random_image ||
				(Object.keys(random_image).length === 0 &&
					random_image.constructor === Object)
			) {
				newSettings.background.images[i] = this.state.background.images[i];
			}
		}
		return newSettings;
	}

	/**
	 * Check if preview is enabled with settings
	 * @return {boolean} | if preview is true or false
	 */
	checkIfPreview() {
		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search);
		if (params.get("preview") === "true") {
			const settings = params.get("settings") ? params.get("settings") : false;
			if (settings) {
				let settingsParsed = JSON.parse(window.atob(settings));
				settingsParsed = this.checkEmptyImages(settingsParsed);
				settingsParsed.preview = true;
				this.setState(settingsParsed, () => {
					this.setUpBackground();
					return true;
				});
			} else {
				this.setState({ preview: true });
			}
		}
		return false;
	}

	/**
	 * Set up background value with background setting from backend
	 */
	setUpBackground() {
		switch (this.state.background.type) {
			case "color":
				this.setState(
					{
						background_value:
							"background-color: " + this.state.background.color + ";",
					},
					() => {
						document
							.getElementById("pageDiv")
							.setAttribute("style", this.state.background_value);
					}
				);
				break;
			case "image":
				this.setState(
					{
						background_value:
							"background-image: url(" + this.state.background.image + ");",
					},
					() => {
						document
							.getElementById("pageDiv")
							.setAttribute("style", this.state.background_value);
					}
				);
				break;
			case "random_image":
				var randomImageIndex = Math.floor(
					Math.random() * this.state.background.images.length
				);
				this.setState(
					{
						background_value:
							"background-image: url(" +
							this.state.background.images[randomImageIndex] +
							");",
					},
					() => {
						document
							.getElementById("pageDiv")
							.setAttribute("style", this.state.background_value);
					}
				);
				break;
			default:
				this.setState({ background_value: "background-color: #208e94" });
				break;
		}
	}

	/**
	 * Handle login submit
	 * @param {object} form | login form data
	 * @param {boolean} mfa | mfa flag to change url to mfa
	 * @param {string} endpointUrl | set endpointUrl
	 */
	handleSubmit(
		params,
		mfa = false,
		enpointUrl = "login/",
		eupa = false,
		eupaAcceptance = false
	) {
		const { t, i18n } = this.props;
		// Set platform and language
		const platform =
			window.settings && window.settings.s1_platform
				? window.settings.s1_platform
				: "qa";
		params.platform = platform;
		params.lang = localStorage.getItem("i18nextLng");
		// Set url (TODO: avoid this by sending correct url when mfa is needed)
		var url = !mfa ? enpointUrl : "mfa_login/";
		if (eupa) {
			url = "eupa/";
			if (eupaAcceptance) {
				params.eupa_acceptance = true;
				this.setState({ eupa: { visible: false } });
			} else {
				params.eupa_acceptance = false;
				this.setState({
					eupa: {
						enabled: false,
						version: "",
						token: "",
						visible: false,
					},
				});
			}
		}
		// If mfa is enabled don't send the password (TODO: avoid this by sending correct params when mfa is needed)
		if (params.password) {
			params.password = mfa ? null : params.password;
		}
		// Start loading and make the request
		this.setState({ loading: true });
		api
			.post(url, params)
			.then((res) => {
				if (res.data.success) {
					// MFA check
					if (res.data.data.mfa_enabled) {
						this.setState({
							mfa: {
								enabled: res.data.data.mfa_enabled,
								engine: res.data.data.mfa_engine,
								token: res.data.data.mfa_token,
							},
						});
					} else {
						if (res.data.data.eupa_enabled) {
							this.setState({
								eupa: {
									enabled: res.data.data.eupa_enabled,
									token: res.data.data.eupa_token,
									visible: res.data.data.eupa_enabled,
								},
							});
						} else {
							const token = res.data.data.token;
							const redirectUrl = res.data.data.redirect_url;
							const userId = res.data.data.user_id;
							let form = document.getElementById("redirect_form");
							form.action = redirectUrl;
							document.getElementById("input_token").value = token;
							document.getElementById("input_userid").value = userId;
							document.getElementById("input_platform").value = platform;
							form.submit();
						}
					}
				} else {
					// Should not reach this code if backend doesn't change
					let msg =
						res.response && res.response.data && res.response.data.result_code
							? res.response.data.result_code
							: t("error");
					this.setState({ resorMsg: i18n.exists(msg) ? t(msg) : t("error") });
				}
				this.setState({ loading: false });
			})
			.catch((err) => {
				let msg =
					err.response && err.response.data && err.response.data.result_code
						? err.response.data.result_code
						: t("error");
				this.setState({
					errorMsg: i18n.exists(msg) ? t(msg) : t("error"),
					loading: false,
				});
			});
	}

	render() {
		const redirectUrlForm = (
			<form action="" method="POST" id="redirect_form" className="d-none">
				<input id="input_token" type="hidden" name="token" value="" />
				<input id="input_userid" type="hidden" name="user_id" value="" />
				<input id="input_platform" type="hidden" name="platform" value="" />
				<button id="btn-redirect" type="submit"></button>
			</form>
		);

		return (
			<div id="wrapper" className="wrapper">
				<LoginHeader
					logo={
						this.state.white_label.enabled &&
						this.state.white_label.logo.visible &&
						this.state.white_label.logo.image
							? this.state.white_label.logo.image
							: null
					}
				/>
				<div id="pageDiv" className="page-content">
					<Clock
						position={this.state.clock.position}
						visible={this.state.clock.visible}
					/>
					<Loader loading={this.state.loading} />
					<LoginForm
						position={this.state.login.position}
						preview={this.state.preview}
						errorMsg={this.state.errorMsg}
						onCloseErrorMsg={() => this.setState({ errorMsg: null })}
						logo={this.state.login.image}
						mfa={this.state.mfa}
						eupa={this.state.eupa}
						samlEnabled={this.state.saml_enabled}
						s1Enabled={this.state.s1auth_enabled}
						onSubmit={this.handleSubmit}
						reload={this.state.reload}
						message={this.state.message}
					/>
					{redirectUrlForm}
				</div>
				<LoginFooter
					footerText={
						this.state.white_label_allowed &&
						this.state.white_label.enabled &&
						this.state.white_label.footer.visible &&
						this.state.white_label.footer.text
							? this.state.white_label.footer.text
							: null
					}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		clock: state.settingsReducers.clock,
		login: state.settingsReducers.login,
		background: state.settingsReducers.background,
		white_label: state.settingsReducers.white_label,
	};
};

export default connect(mapStateToProps, null)(withTranslation()(BaseLogin));
