import React from 'react';
import { Switch, Route } from 'react-router-dom';
import BaseLogin from 'views/BaseLogin/BaseLogin';

const Routes = () => {
    return(
        <Switch>
            <Route exact path='*' component={BaseLogin} />
        </Switch>
    );
}

export default Routes;