import React from "react";
import i18next from "i18next";
import Cookie from 'js-cookie';
import { connect } from "react-redux";
import { showClock, hideClock } from "redux/actions/settings.actions";
// CSS
import "./LoginHeader.scss";
// Assets
import s1Logo from "assets/img/s1-logo.png";

/**
 * Login Header component
 * @param {string} logo | logo url if brand is used, s1 by default 
 */
const LoginHeader = ({logoVisible, logo}) => {
  const headerLogo = (logo && typeof logo === "string" && logo !== "") ? logo : s1Logo;
  return (
    <div className="header bg-primary" id="login_header">
      <div className="row">
        <div className="col">
          <img className="s1-header-logo" src={headerLogo} alt="S1 Logo" />
        </div>
        <div className="col">
            <div className="header-languages float-right">
              <button className="header-link" id="button_es" onClick={() => {changeLanguage('es')}}>ESP</button><span> | </span>
              <button className="header-link" id="button_en" onClick={() => {changeLanguage('en')}}>ENG </button><span> | </span>
              <button className="header-link" id="button_pt" onClick={() => {changeLanguage('pt')}}>POR</button>
            </div>
        </div>
      </div>
    </div>
  );

  function changeLanguage(newLang) {
    Cookie.set("s1lang", newLang, { domain: '.s1gateway.com', path: '/' });
    localStorage.setItem("i18nextLng", newLang);
    i18next.changeLanguage(newLang);
  }
};


export default connect(null, {showClock, hideClock})(LoginHeader);
